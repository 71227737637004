import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

export interface ContainerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: 'sm' | 'lg';
  spacing?: 'sm' | 'md' | 'lg';
}

export default function Container({
  size = 'sm',
  spacing = 'md',
  className,
  children,
  ...props
}: ContainerProps) {

  return (
    <div
      className={classNames(
        'mx-auto px-4 sm:px-8',
        size === 'sm' && 'max-w-2xl',
        size === 'lg' && 'max-w-screen-xl',
        spacing === 'sm' && 'py-4 space-y-4 sm:py-6 sm:space-y-6',
        spacing === 'md' && 'py-6 space-y-6 sm:py-8 sm:space-y-8',
        spacing === 'lg' && 'py-8 space-y-8 sm:py-10 sm:space-y-10',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
