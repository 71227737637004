import classNames from 'classnames';
import { createContext, ReactNode, useEffect } from 'react';

import sessionStorage from '../../Shared/sessionStorage';

interface EmbedValue {
  isEmbedded: boolean;
  embedUrl: string|null;
}

export const EmbedContext = createContext<EmbedValue>({
  isEmbedded: false,
  embedUrl: null,
});

export default function EmbedProvider({
  overwriteIsEmbedded,
  overwriteEmbedUrl,
  children,
}: {
  overwriteIsEmbedded?: boolean;
  overwriteEmbedUrl?: string;
  children?: ReactNode;
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const embedUrlParam = urlParams.get('embed_url');

  // Store the URL of the website that loaded Supporta through the embed.
  // The URL is updated, but never unset, so that it is persisted through routing and rerenders.
  useEffect(() => {
    if (embedUrlParam) {
      sessionStorage.setItem('embed_url', embedUrlParam);
    }
  }, [embedUrlParam]);

  const isEmbedded = typeof overwriteIsEmbedded !== 'undefined'
    ? overwriteIsEmbedded
    : window.parent !== window;
  const embedUrl = isEmbedded ? (overwriteEmbedUrl || sessionStorage.getItem('embed_url')) : null;

  useEffect(() => {
    const { className } = document.body;

    if (isEmbedded) {
      document.body.className = overwriteIsEmbedded ? 'bg-slate-300' : 'bg-transparent';
    } else {
      document.body.className = 'bg-white';
    }

    return () => {
      document.body.className = className;
    };
  }, [isEmbedded]);

  return (
    <EmbedContext.Provider value={{ isEmbedded, embedUrl }}>
      <div className={classNames(isEmbedded && 'p-2 sm:p-8')}>
        <div className={classNames(isEmbedded && 'rounded-xl bg-white max-w-2xl mx-auto shadow-lg')}>
          {children}
        </div>
      </div>
    </EmbedContext.Provider>
  );
}
